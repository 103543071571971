import React from 'react'
import Layout from 'components/layout'
import Head from 'components/head'

const NotFound = () => (
  <Layout locale="nl" className="main-container">
    <Head pageTitle="Pagina niet gevonden" locale="nl" />
    <section className="bukazu bukazu-section-half container">
      <h1>Pagina niet gevonden</h1>
      {/* <p>You just hit a route that doesn&apos;t exist... the sadness.</p> */}
    </section>
  </Layout>
)

export default NotFound
